<template>
  <div
    ref="guestsEl"
    :class="computedClasses"
    v-bind="$attrs"
  >
    <button
      :disabled="disabled"
      type="button"
      @click="openGuestPicker"
    >
      <span class="label">{{ t('guests') }}</span>
      <span>{{ t('guestsValue', guestsCount) }}<template v-if="computedPets">{{ t('withPets') }}</template></span>
    </button>
  </div>

  <ClientOnly v-if="!disabled">
    <div
      v-if="isLargeBreakpoint"
      v-show="showForm"
      ref="floatingEl"
      class="floating-box"
      :style="floatingStyles"
    >
      <FormInputGuestsPicker
        :adults="bookingEstimateParams.adults"
        :children="bookingEstimateParams.children"
        :min-adults="1"
        :max-guests="rvGuests"
        @update:guests="(guests: Guests) => update(guests)"
      />

      <FormSearchInputPetsSwitch
        v-if="!hidePetsSwitch"
        :pets="bookingEstimateParams.pets"
        class="mt-3"
        @update:pets="(pets: boolean) => update({ pets })"
      />

      <div
        ref="floatingArrowEl"
        :style="{
          left:
            middlewareData.arrow?.x != null
              ? `${middlewareData.arrow.x}px`
              : '',

        }"
        class="floating-arrow"
      />
    </div>

    <OverlayDrawer
      v-else
      :visible="showForm"
      hide-header
      @close="showForm = false"
    >
      <FormInputGuestsPicker
        :adults="bookingEstimateParams.adults"
        :children="bookingEstimateParams.children"
        :min-adults="1"
        :max-guests="rvGuests"
        @update:guests="(guests: Guests) => update(guests)"
      />

      <FormSearchInputPetsSwitch
        v-if="!hidePetsSwitch"
        :pets="bookingEstimateParams.pets"
        class="mt-3"
        @update:pets="(pets: boolean) => update({ pets })"
      />

      <template #footer>
        <ZButton
          class="ml-auto"
          @click="showForm = false"
        >
          {{ t('actions.apply') }}
        </ZButton>
      </template>
    </OverlayDrawer>
  </ClientOnly>
</template>

<script setup lang="ts">
import { arrow, autoUpdate, offset, shift, useFloating } from '@floating-ui/vue'
import type { BookingEstimateGuests } from '~/types'

type Guests = {
  adults: number
  children: number
}

const props = defineProps<{
  disabled?: boolean
  adults?: number
  children?: number
  pets?: boolean
  hidePetsSwitch?: boolean
  flush?: boolean
}>()

const emit = defineEmits<{
  update: [guests: BookingEstimateGuests]
}>()

const { t } = useI18n()
const { isLargeBreakpoint } = useBreakpoint()
const { rvGuests } = useRvDetails()
const { bookingEstimateParams, updateGuests } = useBookingEstimate()

const computedAdults = computed(() => props.adults || bookingEstimateParams.value.adults)
const computedChildren = computed(() => props.children || bookingEstimateParams.value.children)
const computedPets = computed(() => props.pets || bookingEstimateParams.value.pets)

const guestsCount = computed(() => {
  return computedAdults.value + computedChildren.value
})

const guestsEl = ref<HTMLDivElement>()
const floatingEl = ref<HTMLDivElement>()
const floatingArrowEl = ref<HTMLDivElement>()

const { floatingStyles, middlewareData } = useFloating(guestsEl, floatingEl, {
  placement: 'bottom',
  middleware: [offset(8), shift({ padding: 8 }), arrow({ element: floatingArrowEl })],
  whileElementsMounted: autoUpdate,
})

const computedClasses = computed(() => [
  'guests',
  {
    flush: props.flush,
  },
])

const showForm = ref(false)

onClickOutside(floatingEl, () => {
  showForm.value = false
}, { ignore: [guestsEl] })

function openGuestPicker() {
  showForm.value = true
}

function update({ adults, children, pets }: { adults?: number, children?: number, pets?: boolean }) {
  const newAdult = adults ?? bookingEstimateParams.value.adults
  const newChildren = children ?? bookingEstimateParams.value.children
  const newPets = pets ?? bookingEstimateParams.value.pets

  updateGuests({
    adults: newAdult,
    children: newChildren,
    pets: newPets,
  })

  emit('update', {
    adults: newAdult,
    children: newChildren,
    pets: newPets,
  })
}
</script>

<style lang="scss" scoped>
.guests {
  border: 1px solid getColor('primary-100');
  border-radius: 0.25rem;

  button {
    text-align: left;
    border: none;
    background: none;
    padding: 0.5rem 1rem;
    width: 100%;
    color: getColor('primary-500');

    .label {
      display: block;
      @include caption;
      color: getColor('primary-350');

      + span {
        @include strong-1;
      }
    }
  }

  &.flush {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;

    button {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.floating-arrow {
  position: absolute;
  width: 12px;
  height: 12px;
  top: -7px;
  transform: rotate( -45deg );
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  background-color: #fff;
}
</style>

<i18n lang="json">
{
  "en": {
    "guests": "Guests",
    "guestsValue": "1 guest | {n} guests",
    "withPets": " with pets"
  },
  "fr": {
    "guests": "Invités",
    "guestsValue": "1 invité | {n} invités",
    "withPets": " avec animaux"
  }
}
</i18n>
