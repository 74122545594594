<template>
  <ZTable>
    <ZTableLineItem
      :value="formatPrice({
        value: financialRenterTotal,
      })"
      total
      value-data-testid="renter-total-cost"
    >
      <template #label>
        {{ t('booking.total') }}
        <span>({{ totalCurrency }})</span>
        <div class="taxes">
          {{ t('taxesAndFees') }}
        </div>
      </template>
    </ZTableLineItem>

    <!-- TODO: To complete in RVZ-20812 -->
    <template v-if="financialCreditsToBeApplied">
      <ZTableLineItem
        :label="t('booking.creditsToBeApplied.text')"
        :tooltip="t('booking.creditsToBeApplied.tooltip')"
        :value="formatPrice({
          value: financialTotalCreditsToBeApplied,
        })"
      />

      <ZTableLineItem
        :label="t('bookThisRvFor')"
        :value="formatPrice({
          value: financialPayToReserve,
        })"
      />
    </template>
  </ZTable>
</template>

<script setup lang="ts">
import type { FinancialCommon, Nullable } from '~/types'

const props = defineProps<{
  financial?: Nullable<FinancialCommon>
}>()

const { t } = useI18n()

const { priceDisplay } = usePrice()
const { selectedCurrencyCode } = useCurrency()
const { canShowInternationalPricing } = useInternationalPricing()
const { rvCurrency, rvCountry } = useRvDetails()

const {
  financialRenterTotal,
  financialCreditsToBeApplied,
  financialTotalCreditsToBeApplied,
  financialPayToReserve,
} = useFinancialData(computed(() => props.financial))

function formatPrice({ value }: { value: number }) {
  return priceDisplay({
    value,
    countryCode: rvCountry.value,
    internationalPricing: true,
  })
}

const totalCurrency = computed(() => {
  if (canShowInternationalPricing.value) {
    return selectedCurrencyCode.value
  }
  return rvCurrency.value
})
</script>

<style lang="scss" scoped>
.taxes {
  @include caption;
  color: getColor('primary-350');
}
</style>

<i18n src="~/locales/common/booking/credits-to-be-applied.json" lang="json" />

<i18n src="~/locales/common/booking.json" lang="json" />

<i18n lang="json">
{
  "en": {
    "taxesAndFees": "Includes taxes & fees",
    "bookThisRvFor": "Book this RV for"
  },
  "fr": {
    "taxesAndFees": "Comprend les taxes et les frais",
    "bookThisRvFor": "Réservez ce VR pour"
  }
}
</i18n>
